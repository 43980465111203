<template>
  <v-container fluid flex="auto">
    <SecondaryNavigation />
    <v-card flat class="ma-3">
      <v-row justify="space-between">
        <v-card-title>
          <SingleTitle title="Trainer Personal Profile" />
        </v-card-title>
        <v-card-actions>
          <v-btn
            @click="updateTrainer"
            v-if="editMode.trainerProfile"
            class="mx-3"
            color="primary"
            slot="activator"
            style="background-color:blue"
            :loading="loading"
            >Save</v-btn
          >
          <v-btn
            @click="enableEditMode"
            v-else
            class="mx-3"
            color="primary"
            style="background-color:blue"
            >Edit</v-btn
          >
          <v-btn v-if="profile.linkedinProfileUrl"
            class="mx-3"
            color="primary"
            :loading="loading"
            @click="scrappeRemainingData"
            style="background-color:blue"
            >Scrape data</v-btn
          >
        </v-card-actions>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-h5 font-weight-bold mx-3 text--primary">
            Stage 1A
          </div>
        </v-col>
      </v-row>
      <v-card-text>
        <v-form v-model="valid" ref="trainerUpdateFrom">
          <v-row justify="center" class="my-4">
            <v-col cols="3" justify="center" class="mx-4">
              <ProfilePicCard
                :isUploadButtonDisplayed="editMode.trainerProfile"
              />
            </v-col>
            <v-col class="mx-4">
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-3 my-3"
              >
                <v-row class="primary--text">
                  First Name
                </v-row>
                <v-row class="text--primary">
                  {{ profile.firstName }}
                </v-row>
              </div>
              <v-text-field
                v-else
                label="First Name *"
                outlined
                v-model="profile.firstName"
                :rules="rules.firstName"
                required
                :disabled="!editMode.trainerProfile"
              ></v-text-field>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-3 my-3"
              >
                <v-row class="primary--text">
                  Registered Email Id
                </v-row>
                <v-row class="text--primary">
                  {{ profile.primaryEmailId }}
                </v-row>
              </div>
              <v-text-field
                v-else
                outlined
                label="Registered Email Id *"
                v-model="profile.primaryEmailId"
                :disabled="!editMode.trainerProfile"
                :rules="rules.emailRules"
                required
              ></v-text-field>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-3 my-3"
              >
                <v-row class="primary--text">
                  Primary Mobile Number
                </v-row>
                <v-row class="text--primary">
                  {{ profile.primaryPhoneNumber }}
                </v-row>
              </div>
              <v-text-field
                v-else
                outlined
                label="Primary Mobile Number *"
                prefix="+91"
                :disabled="!editMode.trainerProfile"
                v-model="profile.primaryPhoneNumber"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col class="mx-4">
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-3 my-3"
              >
                <v-row class="primary--text">
                  Middle Name
                </v-row>
                <v-row class="text--primary">
                  {{ profile.middleName }}
                </v-row>
              </div>
              <v-text-field
                v-else
                outlined
                label="Middle Name"
                v-model="profile.middleName"
                :disabled="!editMode.trainerProfile"
                required
              ></v-text-field>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-3 my-3"
              >
                <v-row class="primary--text">
                  Secondary Email Id
                </v-row>
                <v-row class="text--primary">
                  {{ profile.secondaryEmail }}
                </v-row>
              </div>
              <v-text-field
                v-else
                outlined
                label="Secondary Email Id"
                v-model="profile.secondaryEmail"
                :disabled="!editMode.trainerProfile"
              ></v-text-field>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-3 my-3"
              >
                <v-row class="primary--text">
                  Secondary Mobile Number
                </v-row>
                <v-row class="text--primary">
                  {{ profile.secondaryPhoneNumber }}
                </v-row>
              </div>
              <v-text-field
                v-else
                outlined
                label="Secondary Mobile Number"
                v-model="profile.secondaryPhoneNumber"
                :disabled="!editMode.trainerProfile"
                prefix="+91"
              ></v-text-field>
            </v-col>
            <v-col class="mx-4">
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-3 my-3"
              >
                <v-row class="primary--text">
                  Last Name
                </v-row>
                <v-row class="text--primary">
                  {{ profile.lastName }}
                </v-row>
              </div>
              <v-text-field
                v-else
                outlined
                label="Last Name"
                v-model="profile.lastName"
                :rules="rules.lastName"
                :disabled="!editMode.trainerProfile"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider v-if="!editMode.trainerProfile"></v-divider>
          <div
            v-if="!editMode.trainerProfile"
            class="text-h6 font-weight-bold primary--text mx-2"
          >
            <v-row> Trainer Persona <span>*</span> </v-row>
          </div>
          <div class="text-h6 font-weight-bold mx-3" v-else>
            Trainer Persona <span>*</span>
          </div>
          <v-col cols="6">
            <div
              v-if="!editMode.trainerProfile"
              class="text-h6 font-weight-bold"
            >
              <v-row class="text--primary">
                {{
                  selectTrainerPersona.persona != "Select trainer persona"
                    ? selectTrainerPersona.persona
                    : null
                }}
              </v-row>
            </div>
            <v-select
              v-else
              :items="trainersPersona"
              label=""
              v-model="selectTrainerPersona"
              required
              item-text="persona"
              item-value="persona"
              :disabled="!editMode.trainerProfile"
              placeholder="Select trainer persona"
              :hint="`${selectTrainerPersona.personaValue}`"
              :rules="rules.isRequired"
              persistent-hint
              return-object
              outlined
            ></v-select>
          </v-col>
          <v-divider v-if="!editMode.trainerProfile"></v-divider>
          <v-row>
            <v-col cols="12">
              <div :class="!editMode.trainerProfile ? nonActive : activeClass">
                Headline
              </div>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mb-3"
              >
                {{ profile.summary }}
              </div>
              <v-textarea
                v-else
                v-model="profile.summary"
                outlined
                :disabled="!editMode.trainerProfile"
                name="input-7-4"
                label=""
                required
              ></v-textarea>
              <v-divider v-if="!editMode.trainerProfile"></v-divider>
            </v-col>

            <v-col cols="12">
              <div :class="!editMode.trainerProfile ? nonActive : activeClass">
                Professional Overview <span>*</span>
                <ToolTip
                  :tooltipText="trainerProfileHelpText.professionalOverview"
                />
              </div>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mb-3"
              >
                {{ profile.professionalOverview }}
              </div>
              <v-textarea
                v-else
                outlined
                name="input-7-4"
                label=""
                :disabled="!editMode.trainerProfile"
                required
                v-model="profile.professionalOverview"
              ></v-textarea>
              <v-divider v-if="!editMode.trainerProfile"></v-divider>
            </v-col>
          </v-row>
          <v-col cols="6">
            <div
              class="text-h6 primary--text font-weight-bold mx-n3"
              v-if="!editMode.trainerProfile"
            >
              Link to social media
            </div>
            <div :class="activeClass" v-else>
              Link to social media
            </div>
            <v-col cols="8">
              <v-row
                class="primary--text text-h7 font-weight-bold mx-n6"
                v-if="!editMode.trainerProfile"
              >
                LinkedIn Url
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.linkedinProfileUrl }}
              </div>
              <v-text-field
                v-else
                outlined
                label="LinkedIn *"
                placeholder="type here"
                :disabled="!editMode.trainerProfile"
                v-model="profile.linkedinProfileUrl"
                required
              ></v-text-field>
              <v-row
                class="primary--text text-h7 font-weight-bold mx-n6"
                v-if="!editMode.trainerProfile"
              >
                Twitter
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.socialLinks.twitter }}
              </div>
              <v-text-field
                v-else
                label="Twitter"
                outlined
                placeholder="type here"
                :disabled="!editMode.trainerProfile"
                v-model="profile.socialLinks.twitter"
              ></v-text-field>
              <v-row
                class="primary--text text-h7 font-weight-bold mx-n6"
                v-if="!editMode.trainerProfile"
              >
                Instagram
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.socialLinks.instagram }}
              </div>
              <v-text-field
                v-else
                outlined
                label="Instagram"
                :disabled="!editMode.trainerProfile"
                v-model="profile.socialLinks.instagram"
                placeholder="type here"
              ></v-text-field>
              <v-row
                class="primary--text text-h7 font-weight-bold mx-n6"
                v-if="!editMode.trainerProfile"
              >
                Facebook
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.socialLinks.facebook }}
              </div>
              <v-text-field
                v-else
                outlined
                label="Facebook"
                :disabled="!editMode.trainerProfile"
                v-model="profile.socialLinks.facebook"
                placeholder="type here"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-divider v-if="!editMode.trainerProfile"></v-divider>
          <v-col cols="6">
            <div
              class="text-h6 primary--text font-weight-bold mx-n3"
              v-if="!editMode.trainerProfile"
            >
              Link to personal website
              <ToolTip :tooltipText="trainerProfileHelpText.personalWebsite" />
            </div>
            <div :class="activeClass" v-else>
              Link to personal website
              <ToolTip :tooltipText="trainerProfileHelpText.personalWebsite" />
            </div>
            <v-col cols="8">
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.personalLinks.personalWeb1 }}
              </div>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.personalLinks.personalWeb2 }}
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  placeholder="www.randomblog.com"
                  :disabled="!editMode.trainerProfile"
                  v-model="profile.personalLinks.personalWeb1"
                ></v-text-field>
                <v-text-field
                  outlined
                  placeholder="www.personalwebsite.com"
                  :disabled="!editMode.trainerProfile"
                  v-model="profile.personalLinks.personalWeb2"
                ></v-text-field>
              </div>
            </v-col>
          </v-col>
          <v-divider v-if="!editMode.trainerProfile"></v-divider>
          <v-col cols="6">
            <div
              class="text-h6 primary--text font-weight-bold mx-n3"
              v-if="!editMode.trainerProfile"
            >
              Link to professional network
              <ToolTip
                :tooltipText="trainerProfileHelpText.professionalNetwork"
              />
            </div>
            <div :class="activeClass" v-else>
              Link to professional network
              <ToolTip
                :tooltipText="trainerProfileHelpText.professionalNetwork"
              />
            </div>
            <!-- <div :class="!editMode.trainerProfile?nonActive:activeClass" >
              Link to professional network
            </div> -->
            <v-col cols="8">
              <v-row
                class="primary--text text-h7 font-weight-bold mx-n6"
                v-if="!editMode.trainerProfile"
              >
                Udemy
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.professionalLinks.udemy }}
              </div>
              <v-text-field
                v-else
                label="Udemy"
                outlined
                :disabled="!editMode.trainerProfile"
                v-model="profile.professionalLinks.udemy"
                placeholder="www.udemy.com"
              ></v-text-field>
              <v-row
                class="primary--text text-h7 font-weight-bold mx-n6"
                v-if="!editMode.trainerProfile"
              >
                Pluralsight
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n6"
              >
                {{ profile.professionalLinks.pluralsight }}
              </div>
              <v-text-field
                v-else
                outlined
                label="Pluralsight"
                :disabled="!editMode.trainerProfile"
                v-model="profile.professionalLinks.pluralsight"
                placeholder="www.personalwebsite.com"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-divider v-if="!editMode.trainerProfile"></v-divider>
          <v-col cols="6">
            <div :class="!editMode.trainerProfile ? nonActive : activeClass">
              Address
            </div>
            <div
              class="primary--text text-h7 font-weight-bold"
              v-if="!editMode.trainerProfile"
            >
              House Number or Name
            </div>
            <div
              v-if="!editMode.trainerProfile"
              class="text-h7 font-weight-bold"
            >
              {{ profile.currentAddressDoorNumber }}
            </div>
            <v-text-field
              v-else
              outlined
              label="House Number or Name "
              placeholder="enter house number"
              :disabled="!editMode.trainerProfile"
              v-model="profile.currentAddressDoorNumber"
              required
            ></v-text-field>
            <div
              class="primary--text text-h7 font-weight-bold"
              v-if="!editMode.trainerProfile"
            >
              Street Name
            </div>
            <div
              v-if="!editMode.trainerProfile"
              class="text-h7 font-weight-bold"
            >
              {{ profile.currentAddressStreet }}
            </div>
            <v-text-field
              v-else
              outlined
              label="Street Name "
              placeholder="enter house number"
              :disabled="!editMode.trainerProfile"
              v-model="profile.currentAddressStreet"
              required
            ></v-text-field>
            <div
              class="primary--text text-h7 font-weight-bold"
              v-if="!editMode.trainerProfile"
            >
              Pincode
            </div>
            <div
              v-if="!editMode.trainerProfile"
              class="text-h7 font-weight-bold"
            >
              {{ profile.currentAddressPin }}
            </div>
            <v-text-field
              v-else
              outlined
              label="Pincode"
              placeholder="enter pincode"
              :disabled="!editMode.trainerProfile"
              v-model="profile.currentAddressPin"
              required
            ></v-text-field>
            <div
              class="primary--text text-h7 font-weight-bold"
              v-if="!editMode.trainerProfile"
            >
              City
            </div>
            <div
              v-if="!editMode.trainerProfile"
              class="text-h7 font-weight-bold"
            >
              {{ profile.currentAddressCity }}
            </div>
            <v-text-field
              v-else
              outlined
              label="City *"
              placeholder="enter city"
              :disabled="!editMode.trainerProfile"
              v-model="profile.currentAddressCity"
              required
            ></v-text-field>
            <div
              class="primary--text text-h7 font-weight-bold"
              v-if="!editMode.trainerProfile"
            >
              State
            </div>
            <div
              v-if="!editMode.trainerProfile"
              class="text-h7 font-weight-bold"
            >
              {{ profile.currentAddressState }}
            </div>
            <v-text-field
              v-else
              outlined
              label="State *"
              placeholder="enter state"
              :disabled="!editMode.trainerProfile"
              v-model="profile.currentAddressState"
              required
            ></v-text-field>
            <div
              class="primary--text text-h7 font-weight-bold"
              v-if="!editMode.trainerProfile"
            >
              Country
            </div>
            <div
              v-if="!editMode.trainerProfile"
              class="text-h7 font-weight-bold"
            >
              {{ profile.currentAddressCountry }}
            </div>
            <v-text-field
              v-else
              outlined
              label="Country *"
              :disabled="!editMode.trainerProfile"
              placeholder="enter country"
              v-model="profile.currentAddressCountry"
              required
            ></v-text-field>
          </v-col>
          <v-divider v-if="!editMode.trainerProfile"></v-divider>
          <v-col cols="12">
            <a
              class="text-h6 font-weight-bold  blue--text"
              @click="routeTo"
              v-if="!editMode.trainerProfile"
            >
              Click here to fill admin 1A data
            </a>
          </v-col>
          <div :class="!editMode.trainerProfile ? nonActive : activeClass">
            Languages
          </div>
          <v-row>
            <v-col cols="3" v-if="!editMode.trainerProfile">
              <div class="primary--text text-h7 font-weight-bold">
                Traning delivery language
                <ToolTip
                  :tooltipText="trainerProfileHelpText.trainingLaguage"
                />
              </div>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold"
              >
                {{ profile.languages.traningDelivery.language }}
              </div>
            </v-col>
            <v-col cols="6" v-else>
              <v-autocomplete
                outlined
                chips
                clearable
                deletable-chips
                required
                :disabled="!editMode.trainerProfile"
                label="Traning delivery language"
                v-model="profile.languages.traningDelivery.language"
                :items="languages"
                item-color="blue"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="!editMode.trainerProfile" cols="3">
              <v-row class="primary--text text-h7 font-weight-bold">
                Language proficiency
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n3"
              >
                {{ profile.languages.traningDelivery.profiency }}
              </div>
            </v-col>
            <v-col v-else>
              <v-autocomplete
                outlined
                :items="languageProficiency"
                :disabled="!editMode.trainerProfile"
                v-model="profile.languages.traningDelivery.profiency"
                label="Select language proficiency"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!editMode.trainerProfile" cols="3">
              <div class="primary--text text-h7 font-weight-bold">
                Trainer's regional language
                <ToolTip
                  :tooltipText="trainerProfileHelpText.regionalLanguage"
                />
              </div>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold"
              >
                {{ profile.languages.regionalLanguage.language }}
              </div>
            </v-col>
            <v-col cols="6" v-else>
              <v-autocomplete
                chips
                clearable
                outlined
                deletable-chips
                :disabled="!editMode.trainerProfile"
                label="Trainer's regional language"
                v-model="profile.languages.regionalLanguage.language"
                required
                :items="languages"
                item-color="blue"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="!editMode.trainerProfile" cols="3">
              <v-row class="primary--text text-h7 font-weight-bold">
                Language proficiency
              </v-row>
              <div
                v-if="!editMode.trainerProfile"
                class="text-h7 font-weight-bold mx-n3"
              >
                {{ profile.languages.regionalLanguage.profiency }}
              </div>
            </v-col>
            <v-col v-else>
              <v-autocomplete
                outlined
                :disabled="!editMode.trainerProfile"
                v-model="profile.languages.regionalLanguage.profiency"
                required
                :items="languageProficiency"
                label="Select language proficiency"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-snackbar timeout=5000
      v-model="snackBarTrainerProfile"
    >
      Unable to fetch data from scrapper

      <!-- <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template> -->
    </v-snackbar>
    </v-card>
    <div :class="editMode.trainerProfile ? activeClass : nonActive">
      Admin comments
    </div>
    <AdminComments
      type="TRAINER_PROFILE"
      :showAddButton="editMode.trainerProfile"
    />
  </v-container>
</template>

<script>
import ProfilePicCard from "../../../components/ProfilePicCard/profilePic.vue";
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import SingleTitle from "../../../components/SingleTitle/singleTitle.vue";
import AdminComments from "../../../components/AdminComments/AdminComments.vue";
import ToolTip from "../../../components/Tooltip/tooltip.vue";
import { TrainerProfile } from "../../../data/HelpTexts";
import { mapState } from "vuex";
export default {
  name: "UpdateTrainer",
  components: {
    //BasicForm,
    SecondaryNavigation,
    SingleTitle,
    ProfilePicCard,
    AdminComments,
    ToolTip
  },
  data() {
    return {
      trainerProfileHelpText: TrainerProfile,
      activeClass: "text-h6 font-weight-bold mx-3",
      nonActive: "text-h6 primary--text font-weight-bold",
      selectTrainerPersona: {
        persona: "Select trainer persona",
        personaValue: ""
      },
      snackbar:false,
      trainersPersona: [
        {
          persona: "Freelance Trainer",
          personaValue: "I dedicate my full time in training/coaching"
        },
        {
          persona: "Working Professional but does training",
          personaValue:
            "I’m a working professional. I can provide training/coaching on weekends and holidays"
        },
        {
          persona: "Founder/CEO of a firm in training/consulting",
          personaValue:
            "Primarily I manage my company. I also provide training/coaching as per my availability."
        },
        {
          persona: "Founder/CEO of a Non-Training firm",
          personaValue:
            "Primarily I manage my company. I also provide training/coaching as per my availability."
        },
        {
          persona: "Indepent Trainer & Consultant",
          personaValue:
            "Primarily I work is on implementation and solving real-world problem but I can also do training/coaching"
        },
        {
          persona: "Full-time Employee of a Training-Firm",
          personaValue: "I provide training/coaching as per my availability."
        },
        {
          persona: "Professor",
          personaValue:
            "Primarily I teach in colleges. I can also provide industrial training/coaching as per my expertise and availability."
        }
      ],
      languageProficiency: [
        "Elementary proficiency",
        "Limited working proficiency",
        "Full professional proficiency",
        "Professional working proficiency",
        "Native or bilingual proficiency"
      ],
      createTrainer: true,
      valid: false,
      languages: [
        "Mandarin Chinese",
        "Spanish",
        "English",
        "Hindi",
        "Bengali",
        "Portuguese",
        "Russian",
        "Japanese",
        "Western Punjabi",
        "Marathi",
        "Telugu",
        "Wu Chinese",
        "Turkish",
        "Korean",
        "French",
        "German",
        "Vietnamese",
        "Tamil",
        "Yue Chinese",
        "Urdu (persianised Hindustani)",
        "Javanese",
        "Italian",
        "Egyptian Arabic",
        "Gujarati",
        "Iranian Persian",
        "Bhojpuri",
        "Min Nan Chinese",
        "Hakka Chinese",
        "Jin Chinese",
        "Hausa",
        "Kannada",
        "Indonesian (Indonesian Malay)",
        "Polish",
        "Yoruba",
        "Xiang Chinese",
        "Malayalam",
        "Odia",
        "Maithili",
        "Burmese",
        "Eastern Punjabi",
        "Sunda",
        "Sudanese Arabic",
        "Algerian Arabic",
        "Moroccan Arabic",
        "Ukrainian",
        "Igbo",
        "Northern Uzbek",
        "Sindhi",
        "North Levantine Arabic",
        "Romanian",
        "Tagalog",
        "Dutch",
        "Saʽidi Arabic",
        "Gan Chinese",
        "Amharic",
        "Northern Pashto",
        "Magahi",
        "Thai",
        "Saraiki",
        "Khmer",
        "Chhattisgarhi",
        "Somali",
        "Malaysian (Malaysian Malay)",
        "Cebuano",
        "Nepali",
        "Mesopotamian Arabic",
        "Assamese",
        "Sinhalese",
        "Northern Kurdish",
        "Hejazi Arabic",
        "Nigerian Fulfulde",
        "Bavarian",
        "South Azerbaijani",
        "Greek,Chittagonian",
        "Kazakh",
        "Deccan",
        "Hungarian",
        "Kinyarwanda",
        "Zulu",
        "South Levantine Arabic",
        "Tunisian Arabic",
        "Sanaani Spoken Arabic",
        "Min Bei Chinese",
        "Southern Pashto",
        "Rundi",
        "Czech",
        "Taʽizzi-Adeni Arabic",
        "Uyghur",
        "Min Dong Chinese",
        "Sylheti"
      ],
      rules: {
        isRequired: [(v) => !!v || "Required"],
        firstName: [
          (v) => !!v || "First Name is empty",
          (v) =>
            (v && v.length <= 20) ||
            "First Name must be less than 20 characters"
        ],
        lastName: [
          (v) => !!v || "Last Name is empty",
          (v) =>
            (v && v.length <= 20) || "Last Name must be less than 20 characters"
        ],
        middleName: [
          (v) => !!v || "middle name can't be empty",
          (v) =>
            (v && v.length <= 20) ||
            "Middle name must be less than 20 characters"
        ],
        email: [
          (v) => !!v || "Required",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid"
        ],
        phone: [
          //(v) => !!v || "Can't be empty",
          (v) =>
            (v && v.length == 10 && /^(\d+)$/.test(v)) ||
            "Enter a valid phone number"
        ],
        seconaryEmail: [
          (v) => !!v || "Required",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid"
        ],
        trainerPersona: [
          (v) => (v && v.length !== 0) || "Select trainer persona"
        ],
        headlineRules: [
          (v) => (v && v.length >= 20) || "Add heading to profile"
        ],
        professionalOverview: [
          (v) =>
            (v && v.length >= 20) || "Add professional overview to  profile"
        ],
        linkedIn: [
          (v) =>
            (v && v.includes("www") && v.includes("linkedin")) ||
            "Add a valid linkedin url"
        ],
        twitter: [
          (v) =>
            (v && v.includes("www") && v.includes("twitter")) ||
            "Add a valid twiitter  url"
        ],
        instagram: [
          (v) =>
            (v && v.includes("www") && v.includes("instagram")) ||
            "Add a valid instagram url"
        ],
        facebook: [
          (v) =>
            (v && v.includes("www") && v.includes("facebook")) ||
            "Add a valid facebook url"
        ],
        pluralSight: [
          (v) =>
            (v && v.includes("www") && v.includes("pluralsight")) ||
            "Add a valid pluralsight  url"
        ],
        udemy: [
          (v) =>
            (v && v.includes("www") && v.includes("udemy")) ||
            "Add a valid udemy  url"
        ],
        language: [(v) => (v && v.length != 0) || "Select a langauage"],
        proficiency: [(v) => (v && v.length != 0) || "Select a proficiency"],
        currentAddressStreet: [
          (v) => (v && v.length != 0) || "Enter a street name"
        ],
        currentDoorAddress: [
          (v) => (v && v.length != 0) || "Enter a house name or number"
        ],
        currentAddressCity: [(v) => (v && v.length != 0) || "Enter a city"],
        currentAddressPin: [(v) => (v && v.length != 0) || "Enter a pincode"],
        currentAddressState: [(v) => (v && v.length != 0) || "Enter a state"],
        currentAddressCountry: [
          (v) => (v && v.length != 0) || "Enter a country"
        ]
      }
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      editMode: "editMode",
      snackBarTrainerProfile:"snackBarTrainerProfile"
    })
  },
  created() {
    if (this.profile.trainerPersona != null) {
      let trainerPersona = JSON.parse(this.profile.trainerPersona);
      console.log(trainerPersona.persona);
      if (trainerPersona.persona == "Select trainer persona") {
        console.log("something");
      } else {
        this.selectTrainerPersona = trainerPersona;
      }
    }
  },
  methods: {
    cb() {
      console.log("call back called");
    },
    updateTrainer() {
      console.log("update trainer ");
      if (this.$refs.trainerUpdateFrom.validate()) {
        console.log("form validated");

        let updatedTrainerProfile = { ...this.profile };
        updatedTrainerProfile.trainerPersona = JSON.stringify(
          this.selectTrainerPersona
        );

        console.log("update trainer profile  -----", updatedTrainerProfile);
        this.$store.dispatch("trainerProfile/updateTrainerProfile", {
          trainer: updatedTrainerProfile,
          cb: this.cb
        });
      }
    },
    routeTo() {
      this.$router.push("/pages/adminConsole");
    },
  scrappeRemainingData(){
      // 
    this.$store.dispatch('trainerProfile/getRemainingData',this.profile.trainerId)
    
    },
    enableEditMode() {
      let editMode = this.$store.dispatch(
        "trainerProfile/changeEditMode",
        "UPDATE_TRAINER"
      );
      console.log("edmit mode return ", editMode);
    }
  }
};
</script>
<style></style>
