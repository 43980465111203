<template>
  <v-container fluid>
    <div class="contain-media elevation-1 grey lighten-4 mx-auto">
      <v-icon x-large v-if="trainer.profilePic==null">person</v-icon>
      <img v-else :src="profilePictureSrc"   @error="imageNotValid" alt="Profile Pic">
    </div>
    <div class="text-sm-center" v-if="isUploadButtonDisplayed">
      <v-menu v-model="dialogOpen" top
        :close-on-content-click="false">
      <v-btn @click.native="addFile" class="my-3" color="primary" slot="activator" style="background-color:blue" >Upload  profile picture</v-btn>
      <v-card>
        <v-card-title>
          <span class="headline">Upload Profile Picture</span>
        </v-card-title>
        <v-card-text>
          <input @change="tmpUploadFile" type="file" accept="image/*" ref="imgUploadButton" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"  rounded outlined @click.native="dialogOpen = false">Close</v-btn>
          <v-btn color="primary" rounded @click.native="saveFile" :loading="loader">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-menu>
    </div>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/utils/utilities'
export default {
  name: 'ProfilePicCard',
  data () {
    return {
      dialogOpen: false,
      fileToSend: null,
      imgUploadSrc: '',
      loader:false,
      imgUrl: '', // used in callback to give tmp url
    }
  },
  props: ['isUploadButtonDisplayed'],
  computed: {
    ...mapState('trainerProfile', {
      trainer:'profile',
      loading:'loading',
     profilePictureSrc: 'profilePictureSrc',
    })
  },
  methods: {
    imageNotValid () {
     this.$store.dispatch('trainerProfile/viewProfilePicture')
    },
    cb (src) {
      this.imgUrl = src
    },
    cbProfilePic () {
      this.dialogOpen = false
    },
    tmpUploadFile (event) {
      const files = event.target.files
      if (files.length === 0) {
        this.fileToSend = null
        this.$refs.imgUploadButton.value = ''
        return
      }
      let filename = files[0].name
      if (!utils.getValidMediaType('ImgPrev', filename)) {
        this.imgUploadSrc = ''
        this.fileToSend = null
      } else {
        this.fileToSend = files[0]
        var URL = window.URL || window.webkitURL
        this.imgUploadSrc = URL.createObjectURL(this.fileToSend)
      }
    },
    addFile () {
      this.fileToSend = null
       this.imgUploadSrc = ''
      // this.$refs.imgUploadButton.value = ''
      this.dialogOpen = true
    },
    async saveFile () {
      this.loader=true
    let response =await this.$store.dispatch('trainerProfile/uploadProfilePic', {data: this.fileToSend,trainerId:this.trainer.trainerId , cb: this.cbProfilePic})
    console.log('response ' ,response);
    this.loader=false
    }
  }
}
</script>
<style scoped>
.contain-media {
  height: 240px;
  width: 236px;
}
.contain-media > * {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
</style>
